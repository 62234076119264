import './MapContainer.css';

interface ContainerProps {
  name: string;
}


const MapContainer: React.FC<ContainerProps> = () => {
  return (
    <div id="map" className="map">
    </div>
  );
};

export default MapContainer;
