import { IonContent, IonPage, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import "./Map.css";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import React, { useEffect, useRef } from "react";
import * as L from "leaflet";
import { Geolocation } from "@capacitor/geolocation";
import { StopMarker } from "../components/StopMarker";
import axios from "axios";
import MarkerClusterGroup from "react-leaflet-cluster";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import { Stop, StopData } from "../interfaces/StopData";
import moment from "moment";
import { DeparturesData } from "../interfaces/DeparturesData";
import { useHistory } from "react-router";

const Map: React.FC = () => {
  const history = useHistory<any>();
  let interval = useRef<any>(null);
  const mapRef: React.Ref<L.Map> = useRef(null);
  const markerRef: React.Ref<L.Marker> = useRef(null);
  const [userLocation, setUserLocation] = React.useState<{
    lat: number;
    long: number;
  } | null>(null);
  const [stops, setStops] = React.useState<StopData>();
  const [locationDenied, setLocationDenied] = React.useState<boolean>(false);
  const [dataError, setDataError] = React.useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.invalidateSize();
    }, 250);
    axios
      .get("https://zkm-api.czerwoniakplus.eu.org/v1/stop")
      .then((res) => {
        setStops(res.data);
      })
      .catch(() => {
        setDataError(true);
      });
  }, []);

  useIonViewDidLeave(() => {
    clearInterval(interval.current);
    interval.current = null;
  });

  useIonViewDidEnter(() => {
    window.dispatchEvent(new Event("resize"));
    Geolocation.getCurrentPosition()
      .then((res) => {
        const lat = res.coords.latitude;
        const long = res.coords.longitude;
        setUserLocation({ lat: lat, long: long });
      })
      .catch((err) => {
        setLocationDenied(true);
      });
      if (!interval.current) {
        interval.current = setInterval(() => {
          console.log("location update")
          Geolocation.getCurrentPosition()
            .then((position) => {
              setUserLocation({
                lat: position.coords.latitude,
                long: position.coords.longitude,
              });
              markerRef.current?.setLatLng([
                position.coords.latitude,
                position.coords.longitude,
              ]);
            })
            .catch((error) => {
              setLocationDenied(true);
            });
        }, 5000);
      }
  });

  useEffect(() => {
    if (locationDenied) {
      toast.error("Nie udało się pobrać Twojej lokalizacji.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "dark",
      });
    }
  }, [locationDenied]);

  useEffect(() => {
    if (dataError) {
      toast.error(
        "Nie udało się pobrać danych. Sprawdź połączenie z internetem.",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          theme: "dark",
        }
      );
    }
  }, [dataError]);

  const clusterIcon = () => {
    return StopMarker;
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <ToastContainer />
        <MapContainer
          whenReady={() => {
            Geolocation.getCurrentPosition()
              .then((position) => {
                setUserLocation({
                  lat: position.coords.latitude,
                  long: position.coords.longitude,
                });
                mapRef.current?.flyTo(
                  [position.coords.latitude, position.coords.longitude],
                  18
                );
                console.log(position);
              })
              .catch((error) => {
                setLocationDenied(true);
              });
          }}
          ref={mapRef}
          center={[52.98507426239416, 22.241683114430682]}
          minZoom={14}
          zoom={15}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            iconCreateFunction={clusterIcon}
            showCoverageOnHover={true}
            chunkedLoading
          >
            {stops &&
              stops.map((stop: Stop) => (
                <Marker
                  key={stop.stop_ID}
                  position={[stop.gps_LAT, stop.gps_LONG]}
                  icon={StopMarker}
                >
                  <Popup>
                    <h3 className="stopName">{stop.stop_NAME}</h3>
                    <p
                      className="goToStop"
                      onClick={() => handleStopClick(stop)}
                    >
                      Przejdź do przystanku &raquo;
                    </p>
                  </Popup>
                </Marker>
              ))}
          </MarkerClusterGroup>
          {userLocation && (
            <Marker
              position={[userLocation.lat, userLocation.long]}
              ref={markerRef}
              icon={L.icon({
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })}
            >
              <Tooltip>Twoja lokalizacja</Tooltip>
            </Marker>
          )}
        </MapContainer>
      </IonContent>
    </IonPage>
  );
};

const handleStopClick = async (stop: Stop) => {
  const allDepartures: DeparturesData = (
    await axios.get(
      `https://zkm-api.czerwoniakplus.eu.org/v1/departures/${stop.stop_ID}`
    )
  ).data;
  const nextDepartures: DeparturesData = (
    await axios.post(
      `https://zkm-api.czerwoniakplus.eu.org/v1/departures/${stop.stop_ID}`,
      {
        time: moment().format("HH:mm"),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  ).data;
  nextDepartures.length > 0
    ? handleDepartures(nextDepartures)
    : handleDepartures(allDepartures);
};

const handleDepartures = (departures: DeparturesData) => {
  // todo
};

export default Map;
