import {
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent,
    IonHeader, IonIcon,
    IonInput,
    IonItem,
    IonLabel, IonList,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Home.css';
import React, {useEffect} from "react";
import TimeBasedGreeting from "../components/TimeBasedGreeting";
import axios from "axios";
import {SunData} from "../interfaces/SunData";
import {alertCircle} from "ionicons/icons";
import {Geolocation} from '@capacitor/geolocation'

const Home: React.FC = () => {
        const [sunData, setSunData] = React.useState<SunData | null>(null);
        const [loading, setLoading] = React.useState<boolean>(true);
        const getSunData = async () => {
            // get current high accuracy location
            const position = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true
            }).catch((error) => {
                if (error.code === 1) {
                    setSunData(null);
                }
            });;
            position && setSunData((await axios.get(`https://api.sunrise-sunset.org/json?lat=${position.coords.latitude}&lng=${position.coords.longitude}`)).data);
        }

        useEffect(() => {
            setLoading(false)
            getSunData();
        }, []);

        return (
            <IonPage>
                {loading ? null : (
                    <>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>mZKM - Trasa</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent scrollEvents={true} fullscreen>
                            <IonHeader collapse="condense">
                                <IonToolbar>
                                    <TimeBasedGreeting sunData={sunData}/>
                                </IonToolbar>
                            </IonHeader>
                            <div style={{marginTop: '25px', width: '100%'}}>
                                <IonList>
                                    <IonItem>
                                        <IonLabel>Skąd wyjeżdzamy?</IonLabel>
                                        <IonInput></IonInput>
                                    </IonItem>
                                    <IonItem>
                                        <IonLabel>Gdzie jedziemy?</IonLabel>
                                        <IonInput></IonInput>
                                    </IonItem>
                                    <IonButton style={{float: 'right', margin: '15px'}}>Prowadź!</IonButton>
                                </IonList>
                            </div>
                            <div>
                                <IonList>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <IonIcon style={{display: 'inline-block', verticalAlign: 'middle'}}
                                                         icon={alertCircle} size="large" color='warning'/>
                                                <IonLabel style={{
                                                    marginLeft: '5px',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle'
                                                }}>Lorem ipsum</IonLabel>
                                            </IonCardTitle>
                                            <IonCardSubtitle style={{marginTop: '5px'}}>Opublikowano: poniedziałek, 6
                                                czerwca 2022
                                                15:15:19</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget
                                            aliquam ligula. Aenean vitae dictum libero, ut malesuada libero. Sed vehicula ac
                                            sapien quis efficitur. Duis dui eros, efficitur vitae vehicula at, condimentum
                                            ultricies sem. Sed finibus, tellus sit amet volutpat consequat, purus diam
                                            varius ipsum, non ullamcorper magna arcu id turpis. Suspendisse eu dui mauris.
                                            Nunc in pellentesque dolor.
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <IonLabel style={{
                                                    marginLeft: '5px',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle'
                                                }}>Lorem ipsum</IonLabel>
                                            </IonCardTitle>
                                            <IonCardSubtitle style={{marginTop: '5px'}}>Opublikowano: poniedziałek, 2
                                                kwietnia 2005
                                                21:37:00</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget
                                            aliquam ligula. Aenean vitae dictum libero, ut malesuada libero. Sed vehicula ac
                                            sapien quis efficitur. Duis dui eros, efficitur vitae vehicula at, condimentum
                                            ultricies sem. Sed finibus, tellus sit amet volutpat consequat, purus diam
                                            varius ipsum, non ullamcorper magna arcu id turpis. Suspendisse eu dui mauris.
                                            Nunc in pellentesque dolor.
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <IonLabel style={{
                                                    marginLeft: '5px',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle'
                                                }}>Lorem ipsum</IonLabel>
                                            </IonCardTitle>
                                            <IonCardSubtitle style={{marginTop: '5px'}}>Opublikowano: poniedziałek, 2
                                                kwietnia 2005
                                                21:37:00</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget
                                            aliquam ligula. Aenean vitae dictum libero, ut malesuada libero. Sed vehicula ac
                                            sapien quis efficitur. Duis dui eros, efficitur vitae vehicula at, condimentum
                                            ultricies sem. Sed finibus, tellus sit amet volutpat consequat, purus diam
                                            varius ipsum, non ullamcorper magna arcu id turpis. Suspendisse eu dui mauris.
                                            Nunc in pellentesque dolor.
                                        </IonCardContent>
                                    </IonCard>
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                <IonLabel style={{
                                                    marginLeft: '5px',
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle'
                                                }}>Lorem ipsum</IonLabel>
                                            </IonCardTitle>
                                            <IonCardSubtitle style={{marginTop: '5px'}}>Opublikowano: poniedziałek, 2
                                                kwietnia 2005
                                                21:37:00</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget
                                            aliquam ligula. Aenean vitae dictum libero, ut malesuada libero. Sed vehicula ac
                                            sapien quis efficitur. Duis dui eros, efficitur vitae vehicula at, condimentum
                                            ultricies sem. Sed finibus, tellus sit amet volutpat consequat, purus diam
                                            varius ipsum, non ullamcorper magna arcu id turpis. Suspendisse eu dui mauris.
                                            Nunc in pellentesque dolor.
                                        </IonCardContent>
                                    </IonCard>
                                </IonList>
                            </div>
                        </IonContent>
                    </>
                )}
            </IonPage>
        );
    }
;

export default Home;
