import {IonTitle} from "@ionic/react";
import {SunData} from "../interfaces/SunData";
import moment from "moment";
import React from "react";

interface TimeBasedGreetingProps {
    sunData?: SunData | null;
}

const TimeBasedGreeting: React.FC<TimeBasedGreetingProps> = ({sunData}) => {
    let greeting;
    const now = moment();
    const sunriseMoment = sunData && moment.utc(sunData.results.sunrise, 'h:mm:ss A');
    const sunsetMoment = sunData && moment.utc(sunData.results.sunset, 'h:mm:ss A');
    if (sunriseMoment && sunsetMoment) {
        if (now.isBetween(sunriseMoment, sunsetMoment)) {
            greeting = ('Dzień dobry! 👋');
        } else {
            greeting = ('Dobry wieczór! 👋');
        }
    } else {
        greeting = ('Witaj! 👋');
    }
    return (
        <IonTitle size="large">{greeting}</IonTitle>
    );
}

export default TimeBasedGreeting;
