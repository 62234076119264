import L from "leaflet";
import "./StopMarker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleDot} from "@fortawesome/free-solid-svg-icons";
import {renderToStaticMarkup} from "react-dom/server";

// const StopMarker = new L.Icon({
//   iconUrl: require("../images/busstop_marker.png"),
//   iconRetinaUrl: require("../images/busstop_marker.png"),
//   iconSize: new L.Point(20, 20),
//   className: "leaflet-div-icon",
// });

const StopMarker = L.divIcon({
  className: "leaflet-div-icon",
  html: renderToStaticMarkup(<FontAwesomeIcon size="2xl"  color="#ff0000" icon={faCircleDot} />),
  iconSize: new L.Point(25, 25),
});

export { StopMarker };